.header-main-container {
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--secondary-font-color);
  position: fixed;
  top: 0;
  z-index: 99;
}

.header-logo-container {
  padding-left: 5%;
  font: var(--primary-font);
  font-weight: 900;
  color: var(--primary-font-color);
  cursor: pointer;
}

.header-menu-container {
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
}

.header-menu-item {
  font: var(--primary-font);
  font-weight: 500;
  cursor: pointer;
  color: var(--primary-font-color);
  font-size: var(--font-size-4);
}

/* .header-menu-item:hover {
    background: #E64848;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
  } */

.header-mobile-menu {
  display: none;
}

@media only screen and (max-device-width: 760px) {
  .header-mobile-menu {
    display: block;
  }

  .header-menu-item {
    display: none;
  }
}
